import React, { useEffect } from 'react'
import { SeoHelmet } from '../common/Functions'
import { Link } from '@reach/router'

function Story() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }, [])

  return (
    <main className="px-6 mx-auto max-w-screen-lg lg:px-10">
      <SeoHelmet title="Stories" description="Written by yours truly, here are some of the stories that I'd like to share. Most of them are about the things I've used or had problems using, in my years of being a developer. Hope I can help someone with these." link="/stories" />
      <div className="container flex flex-col flex-wrap items-center justify-between px-0 py-10 mx-auto md:flex-row">
        <div className="z-50 flex flex-col w-full items-left">
          <Link to="/" className="inline-flex text-base text-gray-600 focus:outline-none">
            <svg className="w-6 h-6 mr-3 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            Back
          </Link>
          <div className="mt-10 content lg:mt-16 md:mt-12 sm:mt-8">
            <h1 className="mb-0 text-3xl font-bold text-left lg:text-5xl md:text-4xl">
              Stories
            </h1>
            <p className="text-gray-600">
              Written by yours truly, here are some of the stories that I'd like to share. Most of them are about the things I've used or had problems using, in my years of being a developer. Hope I can help someone with these. 
            </p>
            <div className="mt-20 list">
              <ul>
                <li className="pb-10 mb-10 border-b-2">
                  <p className="my-3 text-xs text-gray-400">2023</p>
                  <a target="blank" href="https://alvinrapada.medium.com/deploying-phoenix-elixir-app-with-gitlab-ci-cd-on-fly-io-6c90241bdb9c" className="items-start text-xl font-semibold text-dark-600 hover:underline hover:text-theme-color">
                    Deploying Phoenix/Elixir App with Gitlab CI/CD on Fly.io
                  </a>
                  <p className="mt-3 text-gray-600">
                    I recently tried deploying my simple Phoenix/Elixir application on Fly.io, but boy did I hit some roadblocks along the way. Some of the resources I was using were outdated, But gladly, I was able to deploy my app successfully! Plus, I set up GitLab CI/CD, to make my life a little easier. So, I decided to write down everything I went through in case anyone else is struggling with the same stuff.
                  </p>
                  <p className="mt-3 text-xs text-theme-color">
                    Mar 9, 2023 &mdash; 6 min read
                  </p>
                </li>
                <li className="pb-10 mb-10 border-b-2">
                  <p className="my-3 text-xs text-gray-400">2022</p>
                  <a target="blank" href="https://alvinrapada.medium.com/getting-google-drive-images-google-drive-api-with-elixir-bbb662dfdee0" className="items-start text-xl font-semibold text-dark-600 hover:underline hover:text-theme-color">
                    Getting Google Drive Images: Google Drive API with Elixir
                  </a>
                  <p className="mt-3 text-gray-600">
                    I'm creating a website for our wedding and my fiance wants to have a public google drive where people can upload their images with us and show them on our site. Seems pretty easy to do (which it was) but while doing it, there are not many resources/documentation on how to do it, so here I am again making one
                  </p>
                  <p className="mt-3 text-xs text-theme-color">
                    Jan 27, 2022 &mdash; 3 min read
                  </p>
                </li>
                <li className="pb-10 mb-10 border-b-2">
                  <p className="my-3 text-xs text-gray-400">2021</p>
                  <a target="blank" href="https://alvinrapada.medium.com/deploy-phoenix-1-6-elixir-1-3-on-render-com-a-working-guide-30241df71807" className="items-start text-xl font-semibold text-dark-600 hover:underline hover:text-theme-color">
                    Deploy Phoenix 1.6 / Elixir 1.3 on Render.com: A Working Guide
                  </a>
                  <p className="mt-3 text-gray-600">
                    Spent almost a week fixing errors after errors trying to deploy my Phoenix/Elixir App on render.com. So I decided to write my journey to hopefully help noobs like me.
                  </p>
                  <p className="mt-3 text-xs text-theme-color">
                    Dec 21, 2021 &mdash; 5 min read
                  </p>
                </li>
                <li className="pb-10 mb-10 border-b-2">
                  <a target="blank" href="https://medium.com/@alvinrapada/caching-in-phoenix-elixir-with-cachex-1ca2c9442618" className="items-start text-xl font-semibold text-dark-600 hover:underline hover:text-theme-color">
                    Caching in Phoenix/Elixir with Cachex
                  </a>
                  <p className="mt-3 text-gray-600">
                    A quick and dumb-down explanation of how to set up cachex in phoenix/elixir and an optional cache table/service management for awesomeness. No more chitchat on why caching is important blah blah blah, let's go straight to coding.
                  </p>
                  <p className="mt-3 text-xs text-theme-color">
                    May 24, 2021 &mdash; 3 min read
                  </p>
                </li>
                <li className="pb-10 mb-10 border-b-2">
                  <a target="blank" href="https://alvinrapada.medium.com/twelixir-my-first-elixir-hex-package-4c6dffeb7076" className="items-start text-xl font-semibold text-dark-600 hover:underline hover:text-theme-color">
                    Twelixir: My First Elixir Hex Package
                  </a>
                  <p className="mt-3 text-gray-600">
                    Being in this open-source community. I'm always fascinated by people willing to share their knowledge and make development a lot easier. Me being at the receiving end (most of the time), I somehow want to do the same, I've always wanted to contribute and create my own elixir hex package, at least in my own little way possible, help other developers too.
                  </p>
                  <p className="mt-3 text-xs text-theme-color">
                    Feb 27, 2021 &mdash; 2 min read
                  </p>
                </li>
                <li className="pb-10 mb-10 border-b-2">
                  <a target="blank" href="https://medium.com/@alvinrapada/phoenix-liveview-chat-app-with-tailwindcss-and-pow-auth-boilerplate-19673ec5dce0" className="items-start text-xl font-semibold text-dark-600 hover:underline hover:text-theme-color">
                    Phoenix LiveView: Chat App with TailwindCSS and POW Auth (Boilerplate)
                  </a>
                  <p className="mt-3 text-gray-600">
                    With my latest discovery of Phoenix LiveView and a fresh mind from <a href="https://pragmaticstudio.com/courses/phoenix-liveview" target="blank" className="font-medium text-black underline">Pragmatic Studio's Phoenix LiveView tutorial</a>, I challenged myself to create a fully functional chat application.
                  </p>
                  <p className="mt-3 text-xs text-theme-color">
                    Feb 19, 2021 &mdash; 1 min read
                  </p>
                </li>
                <li className="pb-10 mb-10 border-b-2">
                  <p className="my-3 text-xs text-gray-400">2020</p>
                  <a target="blank" href="https://medium.com/@alvinrapada/creating-unique-constraint-index-ecto-migration-elixir-37146722e593" className="items-start text-xl font-semibold text-dark-600 hover:underline hover:text-theme-color">
                    Creating Unique Constraint/Index Ecto.Migration: Elixir
                  </a>
                  <p className="mt-3 text-gray-600">
                    Have you ever found yourself in a position where you can find anything about something you really need? Just happen to encounter this problem doing my task for a company I'm working with and I can't find proper documentation about it. So here it is, hope I'm the last one who had troubles trying and finding the right way to do this.
                  </p>
                  <p className="mt-3 text-xs text-theme-color">
                    Dec 12, 2020 &mdash; 2 min read
                  </p>
                </li>
                <li className="pb-10 mb-10 border-b-2">
                  <a target="blank" href="https://medium.com/@alvinrapada/my-favorite-elixir-cheats-782487f78195" className="items-start text-xl font-semibold text-dark-600 hover:underline hover:text-theme-color">
                    Elixir: Cheatsheet
                  </a>
                  <p className="mt-3 text-gray-600">
                    Here are some elixir cheats that I hope can help you code more effectively. I'll continuously update this blog everytime I get to learn new techniques in elixir, I will also appreciate if you have some corrections or other cheats that I can add here. Thanks!
                  </p>
                  <p className="mt-3 text-xs text-theme-color">
                    Nov 10, 2020 &mdash; 3 min read
                  </p>
                </li>
                <li className="pb-10 mb-10 border-b-2">
                  <a target="blank" href="https://medium.com/swlh/ckeditor5-with-custom-image-uploader-on-react-67b4496cb07d" className="items-start text-xl font-semibold text-dark-600 hover:underline hover:text-theme-color">
                    CKEditor5 With Custom Image Uploader in React
                  </a>
                  <p className="mt-3 text-gray-600">
                    Spent almost 2 days figuring out a proper way to use CKEditor5 image uploader. I've tried ckFinder, SimpleUploader, etc. but none of them worked maybe because none of the documentations made any sense to me. Luckily I found a StackOverflow conversation and somehow got what I needed working with just some minor tweaks.
                  </p>
                  <p className="mt-3 text-xs text-theme-color">
                    Aug 20, 2020 &mdash; 2 min read
                  </p>
                </li>
                <li className="pb-10 mb-10 border-b-2">
                  <a target="blank" href="https://medium.com/@alvinrapada/send-slack-message-through-elixir-the-easy-way-7edf5b212dd" className="items-start text-xl font-semibold text-dark-600 hover:underline hover:text-theme-color">
                    Slack Message in Elixr
                  </a>
                  <p className="mt-3 text-gray-600">
                    Seding slack message through elixir, the easy way.
                  </p>
                  <p className="mt-3 text-xs text-theme-color">
                    Feb 13, 2020 &mdash; 3 min read
                  </p>
                </li>
                <li className="pb-10 mb-10 border-b-2">
                  <a target="blank" href="https://medium.com/@alvinrapada/easiest-way-to-add-toaster-inside-phoenix-elixir-app-ce3170480e45" className="items-start text-xl font-semibold text-dark-600 hover:underline hover:text-theme-color">
                    Easiest Way To Add Toaster Inside Phoenix/Elixir App
                  </a>
                  <p className="mt-3 text-gray-600">
                    So I've been finding the easiest way to integrate toaster in my phoenix app, and now I think I found one.
                  </p>
                  <p className="mt-3 text-xs text-theme-color">
                    Feb 3, 2020 &mdash; 2 min read
                  </p>
                </li>
                <li className="pb-10 mb-10 border-b-2">
                  <a target="blank" href="https://medium.com/@alvinrapada/creating-multi-boot-usb-for-catalina-and-mojave-installer-32869fc0b99b" className="items-start text-xl font-semibold text-dark-600 hover:underline hover:text-theme-color">
                    Multi-bootable USB Installer For Catalina and Mojave macOS
                  </a>
                  <p className="mt-3 text-gray-600">
                    First, you need a 16GB or above USB because Mojave is over 6GB and Catalina is over 8GB. we're gonna need to partition your usb into 2(for both OS)...
                  </p>
                  <p className="mt-3 text-xs text-theme-color">
                    Jan 28, 2020 &mdash; 3 min read
                  </p>
                </li>
                <li className="pb-10 mb-10 border-b-2">
                  <p className="my-3 text-xs text-gray-400">2019</p>
                  <a target="blank" href="https://medium.com/@alvinrapada/its-time-to-step-up-don-t-waste-any-opportunity-e451a1477879" className="items-start text-xl font-semibold text-dark-600 hover:underline hover:text-theme-color">
                    It's Time To Step Up: Don't Waste Any Opportunity
                  </a>
                  <p className="mt-3 text-gray-600 italic">
                    All of us have dreams but not a lot of us have plans and the courage to get there.
                  </p>
                  <p className="mt-3 text-xs text-theme-color">
                    Jul 9, 2019 &mdash; 3 min read
                  </p>
                </li>
                <li className="pb-10 mb-10 border-b-2">
                  <a target="blank" href="https://medium.com/@alvinrapada/my-daily-logo-challenge-322fa0cad1d8" className="items-start text-xl font-semibold text-dark-600 hover:underline hover:text-theme-color">
                    My Daily Logo Challenge
                  </a>
                  <p className="mt-3 text-gray-600">
                    I just want to document all of my designs here and let people see and judge me, nice idea right?
                  </p>
                  <p className="mt-3 text-xs text-theme-color">
                    Jan 25, 2019 &mdash; 3 min read
                  </p>
                </li>
                <li className="pb-10 mb-10 border-b-2">
                  <p className="my-3 text-xs text-gray-400">2018</p>
                  <a target="blank" href="https://medium.com/@alvinrapada/chatbot-react-native-with-dialogflow-6db2b91ea85c" className="items-start text-xl font-semibold text-dark-600 hover:underline hover:text-theme-color">
                    Chatbot: React Native with DialogFlow
                  </a>
                  <p className="mt-3 text-gray-600">
                    Assuming you already have your dialogflow setup with intents, and you now want to integrate that with a react native app, well worry no more. Here is the complete code on how to integrate your dialogflow api to your mobile application.
                  </p>
                  <p className="mt-3 text-xs text-theme-color">
                    Dec 13, 2018 &mdash; 1 min read
                  </p>
                </li>
                <li className="pb-10 mb-10 border-b-2">
                  <a target="blank" href="https://medium.com/@alvinrapada/chart-js-in-elixir-phoenix-with-filtering-250c202aab5" className="items-start text-xl font-semibold text-dark-600 hover:underline hover:text-theme-color">
                    Chart.js in Elixir/Phoenix with Filtering
                  </a>
                  <p className="mt-3 text-gray-600">
                    I was assigned to do the dashboard for this web application project were doing at my current job, and because it's dashboard, of course you have charts. Considering that it's just been months since I started doing backend stuff, i was quite pumped and curious on how to implement it on Elixir Phoenix (newbie alert).
                  </p>
                  <p className="mt-3 text-xs text-theme-color">
                    Jul 22, 2018 &mdash; 4 min read
                  </p>
                </li>
                <li className="pb-10 mb-10">
                  <a target="blank" href="https://medium.com/@alvinrapada/crud-graphql-with-elixir-phoenix-e6bee9b6bec2" className="items-start text-xl font-semibold text-dark-600 hover:underline hover:text-theme-color">
                    CRUD: GraphQL with Elixir/Phoenix
                  </a>
                  <p className="mt-3 text-gray-600">
                    I'm currently studying GraphQL with Elixir/Phoenix in preparation for this web and mobile app project. Although it's easy to find tutorials on different sites, I've noticed that there's no source discussing the CRUD process as a whole, so I've decided to do this article to combine each process altogether. Hope it helps.
                  </p>
                  <p className="mt-3 text-xs text-theme-color">
                    Jul 20, 2018 &mdash; 3 min read
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Story
