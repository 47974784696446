import React from 'react'

function Footer() {
  return (
    <footer className="p-2 text-left lg:px-0">
      <div className="px-6 mx-auto max-w-screen-lg lg:px-10">
        <div className="container flex flex-col flex-wrap items-start justify-between py-5 mx-auto">
          <div className="text-xs text-gray-600">
            © 2024 - Alvin Rapada - All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer
