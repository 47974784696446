import React from 'react'
import { SeoHelmet, LazyLoad } from '../common/Functions'
import { Link } from '@reach/router'

function Home() {
  return (
    <main className="px-6 mx-auto max-w-screen-lg lg:px-10">
      <SeoHelmet title="Software Engineer" description="Software Engineer - since 2015" link="/" />
      <div className="container flex flex-col flex-wrap items-center justify-between px-0 py-10 mx-auto md:flex-row">
        <div className="flex flex-col w-full items-left">
          <div className="mt-8 content lg:mt-12 md:mt-10 sm:mt-8">
            <div className="flex flex-col items-center md:flex-row">
              <div className="rounded-full bg-theme-color lg:mr-12 md:mr-10 overflow-hidden w-24 h-24">
                <LazyLoad className="inline-auto alvin-dp" src={require('../common/images/alvin_dp_3.png')} alt="Alvin Profile"/>
              </div>
              <div>
                <h1 className="mb-0 text-3xl font-bold text-center md:text-left lg:text-4xl md:text-3xl">
                  Alvin Rapada
                </h1>
                <p className="text-sm font-bold text-center md:text-left text-theme-color flex flex-row items-center mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 mr-3 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                  <span className="font-medium text-theme-color"> 
                    Software Engineer <span className="text-gray-600"> &mdash; since 2015 </span>
                  </span>
                </p>
                <p className="text-sm font-bold text-center md:text-left text-theme-color flex flex-row items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-3 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  <span className="font-medium  text-theme-color"> Philippines</span>
                </p>
              </div>

            </div>
          </div>
          <div className="mt-10 content lg:mt-12 md:mt-10 sm:mt-8">
            <div className="flex flex-row justify-between list">
              <ul className="ml-4 list-disc">
                <li className="mb-3 text-gray-600"><span className="font-semibold text-black underline">Led a cross-functional team</span> of 8-10 frontend and backend developers, along with QA testers, at Pragtechnologies and Seeyoudoc.</li>
                <li className="mb-3 text-gray-600">Over 6 years of robust experience in Web and API application development across diverse projects, both small and large, within the <span className="font-semibold text-black underline">Phoenix/Elixir ecosystem.</span></li>

              </ul>
            </div>
          </div>
          <div className="mt-10 content lg:mt-12 md:mt-10 sm:mt-8" id="technologies">
            <p className="text-xl font-bold text-left md:text-2xl">
              skill spectrum
            </p>
            <p className="my-3 text-gray-500 text-xs">
              Core Competencies
            </p>
            <div className="flex flex-row justify-between my-3 list">
              <ul className="w-1/3">
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://www.phoenixframework.org/" target="blank">Phoenix Framework</a></li>
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://elixir-lang.org/" target="blank">Elixir</a></li>
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://tailwindcss.com/" target="blank">TailwindCSS</a></li>
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://github.com/alpinejs/alpine" target="blank">AlpineJS</a></li>
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://alvinrapada.medium.com/" target="blank">LiveView</a></li>
              </ul>
              <ul className="w-1/3">
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://alvinrapada.medium.com/crud-graphql-with-elixir-phoenix-e6bee9b6bec2" target="blank">Absinthe/GraphQL API</a></li>
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://powauth.com/" target="blank">POW Auth</a></li>
                <li className="mb-3 text-gray-600"><a href="https://stripe.com/docs/api/subscriptions" target="blank" className="hover:underline">Stripe Integration</a></li>
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://sendbird.com/docs/business-messaging/guide/v2/overview" target="blank">Sendbird Integration</a></li>
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://www.twilio.com/docs" target="blank">Twilio Integration</a></li>
              </ul>
              <ul className="w-1/3">
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://github.com/sorentwo/oban" target="blank">Oban Jobs</a></li>
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://github.com/quantum-elixir/quantum-core" target="blank">Quantum</a></li>
                  <li className="mb-3 text-gray-600 hover:underline"><a href="https://alvinrapada.medium.com/creating-unique-constraint-index-ecto-migration-elixir-37146722e593" target="blank">Postgres/Ecto/Hex</a></li>
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://elixir-lang.org/getting-started/mix-otp/ets.html" target="blank">GenServer/ETS</a></li>
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://github.com/whitfin/cachex" target="blank">Cachex</a></li>
              </ul>
            </div>
            <div className="flex flex-col justify-between my-3 list hidden">
              <p className="mb-3 text-gray-500 text-xs">Machine Learning in Elixir</p>
              <div className="w-full flex">
              <ul className="w-1/3">
                <li className="mb-3 text-gray-600 hover:underline">Nx</li>
                <li className="mb-3 text-gray-600 hover:underline">Axon</li>
                <li className="mb-3 text-gray-600 hover:underline">Scholar</li>
              </ul>
              <ul className="w-1/3">
                <li className="mb-3 text-gray-600 hover:underline">Explorer</li>
                <li className="mb-3 text-gray-600 hover:underline">Bumblebee</li>
                <li className="mb-3 text-gray-600 hover:underline">EXLA</li>
              </ul>
              <ul className="w-1/3">
                <li className="mb-3 text-gray-600 hover:underline">TorchX</li>
                <li className="mb-3 text-gray-600 hover:underline">Livebook</li>
                <li className="mb-3 text-gray-600">Transformer</li>
              </ul>
              </div>
            </div>
            <p className="mb-3 text-gray-500 text-xs">
             Others 
            </p>
            <div className="flex flex-row justify-between mt-3 list">
              <ul className="w-1/3">
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://github.com/ueberauth/ueberauth" target="blank">Überauth</a></li>
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://github.com/beam-community/ex_machina" target="blank">ExMachina</a></li>
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://github.com/tompave/fun_with_flags" target="blank">FunWithFlags</a></li>

              </ul>
              <ul className="w-1/3">
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://www.activecampaign.com/api/overview.php" target="blank">Activecampaign</a></li>
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://developers.klaviyo.com/en/reference/api_overview" target="blank">Klaviyo</a></li>
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://developers.beehiiv.com/docs/v2" target="blank">Beehiiv</a></li>
              </ul>
              <ul className="w-1/3">
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://fly.io/" target="blank">Fly.io</a></li>
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://render.com/" target="blank">Render</a></li>
                <li className="mb-3 text-gray-600 hover:underline"><a href="https://www.netlify.com/" target="blank">Netlify</a></li>
              </ul>
            </div>
          </div>
          <div className="mt-10 content lg:mt-12 md:mt-10 sm:mt-8" id="experiences&involvements">
            <p className="text-xl font-bold text-left md:text-2xl">
             involvements 
            </p>
            <div className="flex flex-col justify-between mt-3 list">
              <p className="mb-3 text-gray-500 text-xs">Companies</p>
              <ul className="mb-3">
                <li className="mb-3 text-gray-600">Phoenix/Elixir Developer &mdash; 
                  <a href="https://blayze.io/" target="blank" className="font-bold text-theme-color underline"> Blayze </a>
                  &mdash; <span className="text-gray-500 text-xs"> Current </span>
                </li>
                <li className="mb-3 text-gray-600">Lead Software Engineer &mdash; 
                  <a href="https://pragtechnologies.com" target="blank" className="font-bold text-theme-color underline"> Pragtechnologies </a>
                  &mdash; <span className="text-gray-500 text-xs"> Aug 2015 - Oct 2021 </span>
                </li>
                <li className="mb-3 text-gray-600">Lead Software Engineer &mdash; 
                <a href="https://www.seeyoudoc.com/" target="blank" className="font-bold text-theme-color underline"> SeeYouDoc </a>
                  &mdash; <span className="text-gray-500 text-xs"> Aug 2015 - Oct 2021 </span>
                </li>
              </ul>
              <p className="mb-3 text-gray-500 text-xs">Projects</p>
              <ul>
                <li className="mb-3 text-gray-600"> Elixir Developer &mdash; 
                  <a href="https://blayze.io/" target="blank" className="font-bold text-theme-color underline"> Blayze App </a>
                  &mdash; <span className="text-gray-500 text-xs"> Current project </span>
                </li>
                <li className="mb-3 text-gray-600"> Lead Elixir Developer &mdash; 
                  <a href="https://www.seeyoudoc.com/" target="blank" className="font-bold text-theme-color underline"> SeeYouDoc App </a>
                  &mdash; <span className="text-gray-500 text-xs"> 4 year project </span>
                </li>
                <li className="mb-3 text-gray-600">Elixir Developer &mdash; 
                  <a href="https://loisysalvacion.netlify.app/works/nac-cmc-omt" target="blank" className="font-bold text-theme-color underline"> NAC CMC-OMT </a>
                  &mdash; <span className="text-gray-500 text-xs"> 3 year project </span>
                </li>
                <li className="mb-3 text-gray-600"> Fullstack Developer &mdash; 
                  <a href="https://www.textstack.io/" target="blank" className="font-bold text-theme-color underline"> TextStack </a>
                  &mdash; <span className="text-gray-500 text-xs"> 2 year project </span>
                </li>
                <li className="mb-3 text-gray-600">Front-End Developer &mdash; 
                  <a href="https://loisysalvacion.netlify.app/works/aims" target="blank" className="font-bold text-theme-color underline"> AIMS </a>
                  &mdash; <span className="text-gray-500 text-xs"> 1 year project </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-10 content lg:mt-12 md:mt-10 sm:mt-8" id="hackathons">
            <p className="text-xl font-bold text-left md:text-2xl">
             hackathons 
            </p>
            <div className="flex flex-row justify-between mt-3 list">
              <ul>
                <li className="mb-3 text-gray-600">ADB-AIM Hack <em>(Finalist)</em> Aug 2018 &mdash; <a href="https://ultrahack.org/" target="blank" className="font-bold text-theme-color underline">Ultrahack</a></li>
                <li className="mb-3 text-gray-600">Turku Business Region Ultrahack <em>(2nd Place)</em> Oct 2018 &mdash; <a href="https://ultrahack.org/" target="blank" className="font-bold text-theme-color underline">Ultrahack</a></li>
              </ul>
            </div>
          </div>
          {/* <div className="mt-10 content lg:mt-12 md:mt-10 sm:mt-8" id="quotes"> */}
          {/*   <p className="text-xl font-bold text-left md:text-2xl"> */}
          {/*    quotes */} 
          {/*   </p> */}
          {/*   <div className="flex flex-row justify-between mt-3 list"> */}
          {/*     <ul> */}
          {/*       <li className="text-gray-600 mb-6"><em>"F*ck motivation, you will not have motivation most days, what you need is discipline, you need to be able to start doing things even though you don’t wanna do it. "</em> &mdash; <span className="font-bold text-theme-color underline">Joe Rogan</span></li> */}
          {/*       <li className="text-gray-600"><em>"I'll leave tomorrow's problems to tomorrow's me."</em> &mdash; <span className="font-bold text-theme-color underline">Saitama</span></li> */}
          {/*     </ul> */}
          {/*   </div> */}
          {/* </div> */}
          <div className="mt-10 content lg:mt-12 md:mt-10 sm:mt-8" id="stories">
            <p className="text-xl font-bold text-left md:text-2xl">
              stories
            </p>
            <div className="flex flex-row justify-between mt-3 mb-10 list">
              <ul>
                <li className="mb-3 text-gray-600 hover:underline">
                  <a target="blank" href="https://alvinrapada.medium.com/deploying-phoenix-elixir-app-with-gitlab-ci-cd-on-fly-io-6c90241bdb9c">
                    Deploying Phoenix/Elixir App with Gitlab CI/CD on Fly.io
 &mdash; <span className="text-xs font-bold text-theme-color"> 6 min read</span></a>
                </li>
                <li className="mb-3 text-gray-600 hover:underline">
                  <a target="blank" href="https://alvinrapada.medium.com/getting-google-drive-images-google-drive-api-with-elixir-bbb662dfdee0">Getting Google Drive Images: Google Drive API with Elixir
 &mdash; <span className="text-xs font-bold text-theme-color"> 3 min read</span></a>
                </li>
                <li className="mb-3 text-gray-600 hover:underline">
                  <a target="blank" href="https://alvinrapada.medium.com/deploy-phoenix-1-6-elixir-1-3-on-render-com-a-working-guide-30241df71807">Deploy Phoenix 1.6 / Elixir 1.3 on Render.com &mdash; <span className="text-xs font-bold text-theme-color"> 5 min read</span></a>
                </li>
                <li className="mb-3 text-gray-600 hover:underline">
                  <a target="blank" href="https://medium.com/@alvinrapada/caching-in-phoenix-elixir-with-cachex-1ca2c9442618">
                    Caching in Phoenix/Elixir with Cachex
 &mdash; <span className="text-xs font-bold text-theme-color"> 3 min read</span></a>
                </li>
                <li className="mb-3 text-gray-600 hover:underline">
                  <a target="blank" href="https://alvinrapada.medium.com/twelixir-my-first-elixir-hex-package-4c6dffeb7076">
                  Twelixir: My First Elixir Hex Package &mdash; <span className="text-xs font-bold text-theme-color"> 2 min read</span>
                  </a>
                </li>
                <li className="mb-3 text-gray-600 hover:underline">
                  <a target="blank" href="https://medium.com/@alvinrapada/phoenix-liveview-chat-app-with-tailwindcss-and-pow-auth-boilerplate-19673ec5dce0">
                  Phoenix LiveView: Chat App with TailwindCSS and POW Auth (Boilerplate) &mdash; <span className="text-xs font-bold text-theme-color"> 1 min read</span></a>
                </li>
              </ul>
            </div>
            <Link to="/stories" className="text-sm font-bold text-theme-color hover:underline">Read more stories</Link>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Home
